import React from "react";
// import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import {
  FaFacebookF,
  FaLinkedinIn,
  FaBehance,
  FaPhone,
  FaFax,
} from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const OffcanvasMenu = ({ offcanvasShow, offcanvasHide }) => {
  return (
    <Offcanvas
      show={offcanvasShow}
      onHide={offcanvasHide}
      placement="end"
      className="header-offcanvasmenu"
    >
      <Offcanvas.Header closeButton></Offcanvas.Header>
      <Offcanvas.Body>
        <form action="#" className="side-nav-search-form">
          {/* <div className="form-group">
            <input
              type="text"
              className="search-field"
              name="search-field"
              placeholder="Search..."
            />
            <button className="side-nav-search-btn">
              <i className="fas fa-search"></i>
            </button>
          </div> */}
        </form>
        <div className="row ">
          <div className="col-lg-3 col-xl-4">
            <ul className="main-navigation list-unstyled">
              <li>Services</li>
              <li>Portfolio</li>
              <li>Contact</li>
            </ul>
          </div>
          <div className="col-lg-9 col-xl-8">
            <div className="contact-info-wrap">
              <div className="contact-inner">
                <address className="address">
                  <span className="title">Contact Information</span>
                  <p>
                    +1 (626) 612-3010 <br />
                    Plot#C-7/8 A street #17 Main Khayabaan-e-jami DHA karachi
                  </p>
                </address>
                <a className="email" href="mailto:info@evolvescode.com">
                  info@evolvescode.com
                </a>
                {/* <address className="address">
                  <span className="title">We're Available 24/7. Call Now.</span>
                  <a href="tel:8884562790" className="tel">
                    <FaPhone /> +1 (626)612-3010
                  </a>
                </address> */}
              </div>
              <div className="contact-inner">
                <h5 className="title">Find us here</h5>
                <div className="contact-social-share">
                  <ul className="social-share list-unstyled">
                    <li>
                      <a href="https://www.facebook.com/evolvescode">
                        <FaFacebookF />
                      </a>
                    </li>

                    <li>
                      <a href="https://twitter.com/EvolvesGlo5943">
                        <FaXTwitter />
                      </a>
                    </li>
                    {/* <li>
                      <a href="https://www.behance.net/">
                        <FaBehance />
                      </a>
                    </li> */}
                    <li>
                      <a href="https://www.linkedin.com/in/evolves-code-021b88189">
                        <FaLinkedinIn />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default OffcanvasMenu;
