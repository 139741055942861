import React from 'react';

const PropOne = ({ projectStyle, portfolio, isApp, handleClick, activeFilter }) => {
  const show = activeFilter === portfolio.category || activeFilter === 'All Works'

  return (
    <div className={`project-grid ${projectStyle} ${isApp ? "mobile" : "web"}`}>
      <div className={`thumbnail ${show ? 'active' : 'd-none'}`}>
        <img onClick={handleClick} src={process.env.PUBLIC_URL + portfolio.image} alt="icon" />
      </div>
      <div className="content">
        <h4 className="title">{portfolio.title}</h4>
        <span className="subtitle">
          {portfolio.body}
          {/* {portfolio.category.map((cat, i) => (
              <span key={i}>{cat}</span>
            ))} */}
        </span>
      </div>
    </div>
  );
};

export default PropOne;
