import React, { useState, useEffect } from "react";
import ProjectPropOne from "./itemProp/ProjectPropOne";
import SectionTitle from "../../elements/section-title/SectionTitle";
import ProjectData from "../../data/project/ProjectData.json";

const filters = [
  {
    id: 1,
    label: "All Works",
  },
  {
    id: 2,
    label: "Web Development",
  },
  {
    id: 3,
    label: "App Development",
  },
];

const AllData = ProjectData;

const ProjectOne = ({ parentClass, colSize, itemShow, columnGap }) => {
  const [getAllItems] = useState(AllData);
  const [visibleItems, setVisibleItems] = useState([]);
  const [activeFilter, setActiveFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    setActiveFilter(filters[0].label);
    setVisibleItems(
      getAllItems.filter(
        (item) => item.id <= itemShow || filters[0].label === "All Works"
      )
    ); // Use itemShow if available, otherwise default to 8
  }, []);

  const handleChange = async (e) => {
    e.preventDefault();
    let target = e.target.textContent;
    setActiveFilter('');

    // Simulate data fetching with setTimeout
    setTimeout(() => {
      setActiveFilter(target);
    }, 50);
  };

  const handleImgClick = (isApp, data) => {
    if (isApp) {
      window.location.href = data.url;
    }
  }

  return (
    <>
      <div
        className={`section section-padding-2 ${parentClass ? parentClass : ""
          }`}
      >
        <div className="container">
          <SectionTitle
            subtitle="Our Project"
            title="Some of our <br>
                        finest work."
            textAlignment="heading-left mb--40"
            textColor=""
          />
          <div className="isotope-button isotope-project-btn">
            {filters.map((filter) => (
              <button
                onClick={handleChange}
                className={filter.label === activeFilter ? "is-checked" : " "}
                key={filter.id}
              >
                {filter.label}
              </button>
            ))}
          </div>
          {isLoading ? (
            <div className="loader"></div>
          ) : (
            <div className={`row ${columnGap ? columnGap : "row-35"}`}>
              {visibleItems.map((data) => {
                const isApp = data.category === "App Development";
                const show = activeFilter === data.category || activeFilter === 'All Works'

                return (
                  <div
                    className={`col-md-4 ${show ? 'active' : 'd-none'}`}
                    key={data.id}
                  >
                    <ProjectPropOne
                      handleClick={() => handleImgClick(isApp, data)}
                      isApp={isApp}
                      projectStyle=""
                      portfolio={data}
                      activeFilter={activeFilter}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <ul className="shape-group-7 list-unstyled">
          <li className="shape shape-1">
            <img
              src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
              alt="circle"
            />
          </li>
          <li className="shape shape-2">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
              alt="Line"
            />
          </li>
          <li className="shape shape-3">
            <img
              src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
              alt="Line"
            />
          </li>
        </ul>
      </div>
    </>
  );
};

export default ProjectOne;
