import React from "react";
import SectionTitle from "../../elements/section-title/SectionTitle";
import CounterUp from "../../component/counterup/CounterUp";

const CounterUpOne = () => {
  return (
    <div className="section section-padding bg-color-dark">
      <div className="container">
        <SectionTitle
          subtitle="Featured Case Study"
          title="Development startup movement"
          description="Fostering startup culture by supporting grassroots initiatives, igniting innovation, and nurturing entrepreneurial spirit for sustainable development."
          textAlignment="heading-light"
          textColor=""
        />
        <div className="row">
          <CounterUp colSize="col-lg-4 col-6" layoutStyle="" evenTopMargin="" />
        </div>
      </div>
    </div>
  );
};

export default CounterUpOne;
