import React from "react";
import FooterOne from "../common/footer/FooterOne";
import HeaderTwo from "../common/header/HeaderTwo";
import SEO from "../common/SEO";
import BannerTwo from "../component/banner/BannerTwo";
// import BrandOne from "../component/brand/BrandOne";
import CounterUpOne from "../component/counterup/CounterUpOne";
import CtaLayoutOne from "../component/cta/CtaLayoutOne";
import ProjectOne from "../component/project/ProjectOne";
import ServicePropOne from "../component/service/ServicePropOne";
// import TestimonialOne from "../component/testimonial/TestimonialOne";
import SectionTitle from "../elements/section-title/SectionTitle";
import AboutOne from "../component/about/AboutOne";
import PricingOne from "../component/pricing/PricingOne";

const CreativeAgency = () => {
  return (
    <>
      <SEO title="Evolves Code" />
      {/* <ColorSwitcher /> */}
      <main className="main-wrapper">
        <HeaderTwo />
        <BannerTwo />
        <div className="section section-padding">
          <div className="container" id="services">
            <SectionTitle
              subtitle="What We Can Do For You"
              title="Services we can <br> help you with"
              description=""
              textAlignment="heading-left mb--20 mb_md--70"
              textColor=""
            />

            <div className="row">
              <ServicePropOne
                colSize="col-lg-4"
                serviceStyle="service-style-2"
                itemShow="3"
                marginTop="yes"
              />
            </div>
          </div>
          <ul className="shape-group-7 list-unstyled">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/circle-2.png"}
                alt="circle"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-2.png"}
                alt="Line"
              />
            </li>
            <li className="shape shape-3">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-1.png"}
                alt="Line"
              />
            </li>
          </ul>
        </div>
        <div id="portfolios">
          <ProjectOne parentClass="bg-color-light" />
        </div>
        <div id="contact">
          <AboutOne />
        </div>
        <div className="section bg-color-light section-padding">
          <div className="container">
            <SectionTitle
              subtitle="Pricing Plan"
              title="We’ve built solutions for..."
              description="Flexible pricing options for freelancers
                        and design teams."
              textAlignment=""
              textColor=""
            />
            <div id="pricing">
              <PricingOne />
            </div>
          </div>
          <ul className="list-unstyled shape-group-3">
            <li className="shape shape-1">
              <img
                src={process.env.PUBLIC_URL + "/images/others/line-1.png"}
                alt="shape"
              />
            </li>
            <li className="shape shape-2">
              <img
                src={process.env.PUBLIC_URL + "/images/others/bubble-4.png"}
                alt="shape"
              />
            </li>
          </ul>
        </div>
        <CounterUpOne />
        {/* <BrandOne /> */}
        <CtaLayoutOne />
        <FooterOne parentClass="" />
      </main>
    </>
  );
};

export default CreativeAgency;
