import React from "react";
import FormOne from "../contact/FormOne";

const AboutOne = () => {
  return (
    <section className="section section-padding-equal bg-color-light">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-us">
              <div className="section-heading heading-left mb-0">
                <span className="subtitle">About Us</span>
                <h2 className="title mb--40">
                  We do design, code &amp; develop.
                </h2>
                <p>
                  At our core, we are passionate about crafting digital
                  experiences that transcend the ordinary. Through a harmonious
                  blend of design, code, and development expertise, we bring
                  visions to life with precision and flair. Our journey begins
                  with meticulous design, where every pixel is thoughtfully
                  placed to create captivating visuals that resonate with your
                  audience.{" "}
                </p>
                <p>
                  Beyond mere execution, we thrive on innovation, constantly
                  pushing the boundaries of what's possible in the digital
                  realm. Our holistic approach to design, code, and development
                  enables us to deliver solutions that not only meet your
                  immediate needs but also future-proof your digital presence.{" "}
                </p>
              </div>
            </div>
          </div>

          <div className="col-xl-5 col-lg-6 offset-xl-1">
            <div className="contact-form-box">
              <h3 className="title">Get a free Keystroke quote now</h3>
              <FormOne />
            </div>
          </div>
        </div>
      </div>
      <ul className="shape-group-6 list-unstyled">
        <li className="shape shape-1">
          <img
            src={process.env.PUBLIC_URL + "/images/others/bubble-7.png"}
            alt="Bubble"
          />
        </li>
        <li className="shape shape-2">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-4.png"}
            alt="line"
          />
        </li>
        <li className="shape shape-3">
          <img
            src={process.env.PUBLIC_URL + "/images/others/line-5.png"}
            alt="line"
          />
        </li>
      </ul>
    </section>
  );
};

export default AboutOne;
