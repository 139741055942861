import React from "react";
import { AnimationOnScroll } from "react-animation-on-scroll";
// import { Link } from "react-router-dom";

const BannerTwo = () => {
  const scrollToSection = () => {
    const element = document.getElementById("portfolios");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="banner banner-style-2">
      <div className="container-fluid">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="banner-content">
              <h1 className="title">
                {" "}
                Empowering Sales with Innovative Technology
              </h1>
              <p
                onClick={scrollToSection}
                style={{ maxWidth: "300px", cursor: "pointer" }}
                className="axil-btn btn-fill-white btn-large"
              >
                View Showcase
              </p>
            </div>
          </div>
        </div>
      </div>
      <ul className="list-unstyled shape-group-18 ">
        <li className="shape shape-1 ">
          <AnimationOnScroll
            animateIn="slideInRight"
            duration={1}
            animateOnce={true}
            delay={100}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/banner/banner.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
        <li className="shape shape-2">
          <AnimationOnScroll
            animateIn="slideInLeft"
            // animateIn="zoomIn"
            duration={1}
            animateOnce={true}
          >
            <img
              src={process.env.PUBLIC_URL + "/images/banner/banner-shape-2.png"}
              alt="Shape"
            />
          </AnimationOnScroll>
        </li>
      </ul>
    </div>
  );
};

export default BannerTwo;
