import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PricingData from "../../data/pricing/PricingData.json";
import { FaCheck } from "react-icons/fa";

const getPriceingData = PricingData;

const billed = [
  {
    id: 1,
    label: "Web Development",
  },
  {
    id: 2,
    label: "Mobile App Development",
  },
];

const PricingOne = () => {
  const [isActive, setActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setActive(billed[0].label.toLowerCase());
  }, []);

  const handleChange = async (e) => {
    setIsLoading(true); // Show loader when switching between billed sections
    setActive(e.target.textContent.toLowerCase());
    // Simulate data fetching with setTimeout
    setTimeout(() => {
      setIsLoading(false); // Hide loader after a delay
    }, 600); // Adjust the timeout as needed
  };

  const isWeb = billed[0].label.toLowerCase() === isActive;

  return (
    <>
      <div className="pricing-billing-duration">
        <ul>
          {billed.map((data) => (
            <li className="nav-item" key={data.id}>
              <button
                onClick={handleChange}
                className={`nav-link ${
                  data.label.toLocaleLowerCase() === isActive ? "active" : ""
                }`}
              >
                {data.label}
              </button>
            </li>
          ))}
        </ul>
      </div>
      {isLoading ? ( // Show loader if isLoading is true
        <div className="loader"></div>
      ) : (
        <div className="row">
          {getPriceingData.map((data, index) => (
            <div className="col-lg-4" key={index}>
              <div className="pricing-table pricing-borderd">
                <div className="pricing-header">
                  <h3 className="title">{data.title}</h3>
                  <span className="subtitle">{data.subtitle}</span>
                  <div className="price-wrap">
                    <div className="yearly-pricing">
                      <span className="amount">
                        {isWeb ? data.webPrice : data.mobilePrice}
                      </span>
                      <span className="duration">
                        {isWeb ? data.yduration : data.mduration}
                      </span>
                    </div>
                  </div>
                  <div className="pricing-btn">
                    <Link to="#" className="axil-btn btn-large btn-borderd">
                      Get Started Today
                    </Link>
                  </div>
                </div>
                <div className="pricing-body">
                  <ul className="list-unstyled">
                    {isWeb
                      ? data.facilityWebsite.map((data, index) => (
                          <li key={index}>
                            <FaCheck /> {data}
                          </li>
                        ))
                      : data.facilityMobile.map((data, index) => (
                          <li key={index}>
                            <FaCheck /> {data}
                          </li>
                        ))}
                  </ul>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default PricingOne;
