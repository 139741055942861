import React from "react";
import { Link } from "react-router-dom";
// import { FaAngleDown } from "react-icons/fa";

const Nav = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    console.log(element);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <nav className="mainmenu-nav">
      <ul className="mainmenu">
        {/* <li className="menu-item-has-children">
                    <Link to="#">Digital Agency <FaAngleDown /> </Link>
                    <ul className="axil-submenu">
                        <li><Link to={process.env.PUBLIC_URL + "/digital-agency"}>Digital Agency</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/creative-agency"}>Creative Agency</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/personal-portfolio"}>Personal Portfolio</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/home-startup"}>Home Startup</Link></li>
                        <li><Link to={process.env.PUBLIC_URL + "/corporate-agency"}>Corporate Agency</Link></li>
                        <li><a href="https://new.axilthemes.com/demo/react/abstrak-rtl/">RTL Demo</a></li>
                    </ul>
                </li> */}
        <li className="menu-item-has-children">
          <Link to="#" onClick={() => scrollToSection("services")}>
            Services
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#" onClick={() => scrollToSection("portfolios")}>
            Portfolio
          </Link>{" "}
          {/* <ul className="axil-submenu">
            <li>
              <Link to={process.env.PUBLIC_URL + "/project-grid-one"}>
                Two Column
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/project-grid-two"}>
                Three Column
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/project-grid-three"}>
                Four Column
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/project-width-one"}>
                Three Column Width
              </Link>
            </li>
            <li>
              <Link to={process.env.PUBLIC_URL + "/project-width-two"}>
                Four Column Width
              </Link>
            </li>
            <li>
              <Link
                to={process.env.PUBLIC_URL + "/project-details/plan-management"}
              >
                Portfolio Details
              </Link>
            </li>
          </ul> */}
        </li>
        {/* <li className="menu-item-has-children">
          <Link to={process.env.PUBLIC_URL + "/about-us"}>About Us</Link>
        </li> */}
        <li className="menu-item-has-children">
          <Link to="#" onClick={() => scrollToSection("contact")}>
            Contact
          </Link>
        </li>
        <li className="menu-item-has-children">
          <Link to="#" onClick={() => scrollToSection("pricing")}>
            Pricing plan
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
